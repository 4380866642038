import React, { useState, useEffect } from 'react';
import axios from '../axios';
import AddMovementModal from './AddMovementModal';
import EditMovementModal from './EditMovementModal';
import { FaEdit, FaTrash } from 'react-icons/fa';
import dayjs from 'dayjs';
import * as XLSX from 'xlsx'; // Import XLSX per creare il file Excel
import { saveAs } from 'file-saver'; // Import FileSaver per il download

const MovementsList = ({ account }) => {
    const [movements, setMovements] = useState([]);
    const [filteredMovements, setFilteredMovements] = useState([]);
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [selectedMovement, setSelectedMovement] = useState(null);

    const [filters, setFilters] = useState({
        aorb: '',
        movimento: '',
        dateFrom: '',
        dateTo: '',
        tipo: '',
        pagato: '',
    });
    const exportToExcel = () => {
        // Filtra i campi che desideri esportare
        const exportableData = filteredMovements.map(({ _id, ...movement }) => ({
            Scadenza: movement.scadenza,
            Descrizione: movement.descrizione,
            Cliente: movement.cliefor,
            Tipo: movement.tipo,
            Movimento: movement.movimento,
            Conto: movement.conto,
            Importo: movement.importo.toFixed(2), // Arrotonda a 2 decimali
            Pagato: movement.pagato ? 'Sì' : 'No',
            'A o B': movement.aorb,
        }));
    
        const worksheet = XLSX.utils.json_to_sheet(exportableData); // Converte i dati JSON in un foglio di lavoro
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Movements');
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: 'application/octet-stream' });
        saveAs(data, 'movements.xlsx'); // Salva il file con il nome 'movements.xlsx'
    };
    
    const fetchMovements = async () => {
        try {
            const response = await axios.get('/movements', { params: { account } });
            console.log('Movements fetched:', response.data); // Aggiungi questo log
            setMovements(response.data);
            setFilteredMovements(response.data);
        } catch (error) {
            console.error('Error fetching movements:', error);
        }
    };

    useEffect(() => {
        fetchMovements();
    }, [account]);

    const applyFilters = () => {
        let filtered = movements;

        if (filters.aorb) {
            filtered = filtered.filter(m => m.aorb === filters.aorb);
        }
        if (filters.movimento) {
            filtered = filtered.filter(m => m.movimento === filters.movimento);
        }
        if (filters.dateFrom) {
            filtered = filtered.filter(m => new Date(m.scadenza) >= new Date(filters.dateFrom));
        }
        if (filters.dateTo) {
            filtered = filtered.filter(m => new Date(m.scadenza) <= new Date(filters.dateTo));
        }
        if (filters.tipo) {
            filtered = filtered.filter(m => m.tipo === filters.tipo);
        }
        if (filters.pagato !== '') {
            filtered = filtered.filter(m => m.pagato === (filters.pagato === 'true'));
        }

        console.log('Filtered movements:', filtered); // Aggiungi questo log
        setFilteredMovements(filtered);
    };

    useEffect(() => {
        applyFilters();
    }, [filters, movements]);

    const handleFilterChange = (name, value) => {
        setFilters({
            ...filters,
            [name]: filters[name] === value ? '' : value,
        });
    };

    const handleCurrentMonth = () => {
        const startOfMonth = dayjs().startOf('month').format('YYYY-MM-DD');
        const endOfMonth = dayjs().endOf('month').format('YYYY-MM-DD');
        setFilters({
            ...filters,
            dateFrom: startOfMonth,
            dateTo: endOfMonth,
        });
    };

    const handleNextMonth = () => {
        const startOfNextMonth = dayjs().add(1, 'month').startOf('month').format('YYYY-MM-DD');
        const endOfNextMonth = dayjs().add(1, 'month').endOf('month').format('YYYY-MM-DD');
        setFilters({
            ...filters,
            dateFrom: startOfNextMonth,
            dateTo: endOfNextMonth,
        });
    };

    const openAddModal = () => {
        setIsAddModalOpen(true);
    };

    const closeAddModal = () => {
        setIsAddModalOpen(false);
    };

    const openEditModal = (movement) => {
        setSelectedMovement(movement);
        setIsEditModalOpen(true);
    };

    const closeEditModal = () => {
        setSelectedMovement(null);
        setIsEditModalOpen(false);
    };

    const handleAddSuccess = () => {
        fetchMovements(); // Aggiorna la lista dei movimenti
    };

    const handleEditSuccess = () => {
        fetchMovements(); // Aggiorna la lista dei movimenti
    };

    const handleDelete = async (movementId) => {
        const confirmDelete = window.confirm('Sei sicuro di voler eliminare questo movimento?');
        if (confirmDelete) {
            try {
                await axios.delete(`/movements/${movementId}`);
                fetchMovements(); // Aggiorna la lista dei movimenti dopo l'eliminazione
            } catch (error) {
                console.error('Error deleting movement:', error);
            }
        }
    };

    const totalEntrata = filteredMovements
    .filter(m => m.tipo === 'entrata')
    .reduce((sum, m) => sum + m.importo, 0)
    .toFixed(2);

const totalUscita = filteredMovements
    .filter(m => m.tipo === 'uscita')
    .reduce((sum, m) => sum + m.importo, 0)
    .toFixed(2);

    const entrate = filteredMovements.filter(movement => movement.tipo === 'entrata');
    const fixedExpenses = filteredMovements.filter(movement => movement.tipo !== 'entrata' && movement.movimento === 'fissa');
    const variableExpenses = filteredMovements.filter(movement => movement.tipo !== 'entrata' && movement.movimento === 'variabile');

    return (
        <div className="container mx-auto p-4 mb-12 bg-gray-100 rounded-lg shadow-md">
            <h1 className="text-3xl font-bold mb-6 text-gray-700">Movimenti</h1>
            <div className="flex justify-between mb-4">
                <button onClick={openAddModal} className="bg-blue-500 text-white p-3 rounded hover:bg-blue-600 transition duration-200">Aggiungi Movimento</button>
                <div className="flex space-x-2">
                <button onClick={handleCurrentMonth} className="bg-green-500 text-white p-3 rounded hover:bg-green-600 transition duration-200">Mese Corrente</button>
                    <button onClick={handleNextMonth} className="bg-yellow-500 text-white p-3 rounded hover:bg-yellow-600 transition duration-200">Successivo</button>
                    <button onClick={exportToExcel} className="bg-purple-500 text-white p-3 rounded hover:bg-purple-600 transition duration-200">Esporta XLS</button>
                </div>
            </div>

            <div className="grid grid-cols-2 gap-4 mb-4">
                <div>
                    <label className="block mb-2 text-gray-600">Data di Scadenza Da</label>
                    <input type="date" name="dateFrom" value={filters.dateFrom} onChange={(e) => handleFilterChange('dateFrom', e.target.value)} className="border p-2 w-full rounded" />
                </div>
                <div>
                    <label className="block mb-2 text-gray-600">Data di Scadenza A</label>
                    <input type="date" name="dateTo" value={filters.dateTo} onChange={(e) => handleFilterChange('dateTo', e.target.value)} className="border p-2 w-full rounded" />
                </div>
            </div>

            <div className="flex flex-wrap justify-between mb-4">
                <div className="flex flex-col mb-4">
                    <label className="block mb-2 text-gray-600">A o B</label>
                    <div className="flex space-x-2">
                        <button
                            className={`p-2 rounded ${filters.aorb === 'A' ? 'bg-blue-500 text-white' : 'bg-gray-200 hover:bg-gray-300'}`}
                            onClick={() => handleFilterChange('aorb', 'A')}
                        >
                            A
                        </button>
                        <button
                            className={`p-2 rounded ${filters.aorb === 'B' ? 'bg-blue-500 text-white' : 'bg-gray-200 hover:bg-gray-300'}`}
                            onClick={() => handleFilterChange('aorb', 'B')}
                        >
                            B
                        </button>
                    </div>
                </div>
                <div className="flex flex-col mb-4">
                    <label className="block mb-2 text-gray-600">Movimento</label>
                    <div className="flex space-x-2">
                        <button
                            className={`p-2 rounded ${filters.movimento === 'fissa' ? 'bg-blue-500 text-white' : 'bg-gray-200 hover:bg-gray-300'}`}
                            onClick={() => handleFilterChange('movimento', 'fissa')}
                        >
                            Spesa Fissa
                        </button>
                        <button
                            className={`p-2 rounded ${filters.movimento === 'variabile' ? 'bg-blue-500 text-white' : 'bg-gray-200 hover:bg-gray-300'}`}
                            onClick={() => handleFilterChange('movimento', 'variabile')}
                        >
                            Spesa Variabile
                        </button>
                    </div>
                </div>
                <div className="flex flex-col mb-4">
                    <label className="block mb-2 text-gray-600">Tipo</label>
                    <div className="flex space-x-2">
                        <button
                            className={`p-2 rounded ${filters.tipo === 'entrata' ? 'bg-blue-500 text-white' : 'bg-gray-200 hover:bg-gray-300'}`}
                            onClick={() => handleFilterChange('tipo', 'entrata')}
                        >
                            Entrata
                        </button>
                        <button
                            className={`p-2 rounded ${filters.tipo === 'uscita' ? 'bg-blue-500 text-white' : 'bg-gray-200 hover:bg-gray-300'}`}
                            onClick={() => handleFilterChange('tipo', 'uscita')}
                        >
                            Uscita
                        </button>
                    </div>
                </div>
                <div className="flex flex-col mb-4">
                    <label className="block mb-2 text-gray-600">Pagato</label>
                    <div className="flex space-x-2">
                        <button
                            className={`p-2 rounded ${filters.pagato === 'true' ? 'bg-blue-500 text-white' : 'bg-gray-200 hover:bg-gray-300'}`}
                            onClick={() => handleFilterChange('pagato', 'true')}
                        >
                            Pagato
                        </button>
                        <button
                            className={`p-2 rounded ${filters.pagato === 'false' ? 'bg-blue-500 text-white' : 'bg-gray-200 hover:bg-gray-300'}`}
                            onClick={() => handleFilterChange('pagato', 'false')}
                        >
                            Non Pagato
                        </button>
                    </div>
                </div>
            </div>

            <div>
                <h2 className="text-xl font-semibold mb-2 text-gray-700">Entrate</h2>
                <ul className="space-y-4">
                    {entrate.map(movement => (
                        <li key={movement._id} className={`p-4 rounded-lg shadow-md border-t-4 border-green-500`}>
                            <div className="grid grid-cols-2 gap-4">
                                <div>
                                    <p><strong>Scadenza:</strong> {new Date(movement.scadenza).toLocaleDateString()}</p>
                                    <p><strong>Descrizione:</strong> {movement.descrizione}</p>
                                </div>
                                <div>
                                    <p><strong>Cliente:</strong> {movement.cliefor}</p>
                                    <p><strong>Tipo:</strong> {movement.tipo}</p>
                                </div>
                                <div>
                                    <p><strong>Movimento:</strong> {movement.movimento}</p>
                                    <p><strong>Conto:</strong> {movement.conto}</p>
                                </div>
                                <div>
                                    <p><strong>Importo:</strong> {movement.importo}</p>
                                    <p><strong>A o B:</strong> {movement.aorb}</p>
                                </div>
                                {!movement.pagato && (
                                    <div className="flex items-center col-span-2">
                                        <span className="bg-red-500 h-4 w-4 rounded-full"></span>
                                        <span className="ml-2">Non Pagato</span>
                                    </div>
                                )}
                                <div className="flex justify-end col-span-2 space-x-2">
                                    <button onClick={() => openEditModal(movement)} className="bg-transparent text-gray-700 p-2 rounded hover:bg-gray-200 transition duration-200">
                                        <FaEdit className="text-2xl" />
                                    </button>
                                    <button onClick={() => handleDelete(movement._id)} className="bg-transparent text-red-700 p-2 rounded hover:bg-red-200 transition duration-200">
                                        <FaTrash className="text-2xl" />
                                    </button>
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>

            <div>
                <h2 className="text-xl font-semibold mb-2 mt-5 text-gray-700">Spese Fisse</h2>
                <ul className="space-y-4">
                    {fixedExpenses.map(movement => (
                        <li key={movement._id} className={`p-4 rounded-lg shadow-md border-t-4 border-red-500`}>
                            <div className="grid grid-cols-2 gap-4">
                                <div>
                                    <p><strong>Scadenza:</strong> {new Date(movement.scadenza).toLocaleDateString()}</p>
                                    <p><strong>Descrizione:</strong> {movement.descrizione}</p>
                                </div>
                                <div>
                                    <p><strong>Fornitore:</strong> {movement.cliefor}</p>
                                    <p><strong>Tipo:</strong> {movement.tipo}</p>
                                </div>
                                <div>
                                    <p><strong>Movimento:</strong> {movement.movimento}</p>
                                    <p><strong>Conto:</strong> {movement.conto}</p>
                                </div>
                                <div>
                                    <p><strong>Importo:</strong> {movement.importo}</p>
                                    <p><strong>A o B:</strong> {movement.aorb}</p>
                                </div>
                                {!movement.pagato && (
                                    <div className="flex items-center col-span-2">
                                        <span className="bg-red-500 h-4 w-4 rounded-full"></span>
                                        <span className="ml-2">Non Pagato</span>
                                    </div>
                                )}
                                <div className="flex justify-end col-span-2 space-x-2">
                                    <button onClick={() => openEditModal(movement)} className="bg-transparent text-gray-700 p-2 rounded hover:bg-gray-200 transition duration-200">
                                        <FaEdit className="text-2xl" />
                                    </button>
                                    <button onClick={() => handleDelete(movement._id)} className="bg-transparent text-red-700 p-2 rounded hover:bg-red-200 transition duration-200">
                                        <FaTrash className="text-2xl" />
                                    </button>
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
            <div>
                <h2 className="text-xl font-semibold mb-2 mt-5 text-gray-700">Spese Variabili</h2>
                <ul className="space-y-4">
                    {variableExpenses.map(movement => (
                        <li key={movement._id} className={`p-4 rounded-lg shadow-md border-t-4 border-red-500`}>
                            <div className="grid grid-cols-2 gap-4">
                                <div>
                                    <p><strong>Scadenza:</strong> {new Date(movement.scadenza).toLocaleDateString()}</p>
                                    <p><strong>Descrizione:</strong> {movement.descrizione}</p>
                                </div>
                                <div>
                                    <p><strong>Fornitore:</strong> {movement.cliefor}</p>
                                    <p><strong>Tipo:</strong> {movement.tipo}</p>
                                </div>
                                <div>
                                    <p><strong>Movimento:</strong> {movement.movimento}</p>
                                    <p><strong>Conto:</strong> {movement.conto}</p>
                                </div>
                                <div>
                                    <p><strong>Importo:</strong> {movement.importo}</p>
                                    <p><strong>A o B:</strong> {movement.aorb}</p>
                                </div>
                                {!movement.pagato && (
                                    <div className="flex items-center col-span-2">
                                        <span className="bg-red-500 h-4 w-4 rounded-full"></span>
                                        <span className="ml-2">Non Pagato</span>
                                    </div>
                                )}
                                <div className="flex justify-end col-span-2 space-x-2">
                                    <button onClick={() => openEditModal(movement)} className="bg-transparent text-gray-700 p-2 rounded hover:bg-gray-200 transition duration-200">
                                        <FaEdit className="text-2xl" />
                                    </button>
                                    <button onClick={() => handleDelete(movement._id)} className="bg-transparent text-red-700 p-2 rounded hover:bg-red-200 transition duration-200">
                                        <FaTrash className="text-2xl" />
                                    </button>
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>

            <div className="fixed bottom-0 left-0 right-0 bg-white p-4 border-t-2 border-gray-200 flex justify-between">
                <p className="text-gray-700"><strong>Totale Entrata:</strong> {totalEntrata}</p>
                <p className="text-gray-700"><strong>Totale Uscita:</strong> {totalUscita}</p>
            </div>

            <AddMovementModal isOpen={isAddModalOpen} onRequestClose={closeAddModal} account={account} onAddSuccess={handleAddSuccess} />
            <EditMovementModal isOpen={isEditModalOpen} onRequestClose={closeEditModal} account={account} movement={selectedMovement} onEditSuccess={handleEditSuccess} />
        </div>
    );
};

export default MovementsList;
